.grid-container {
  display: grid; /* use grid layout */
  grid-template-columns: repeat(2, 1fr); /* 4 columns with equal width */
  grid-gap: 10px; /* gaps between the grid items */
  padding: 10px; /* container padding */
  grid-template-rows: auto auto auto;
}



.grid-container-drain {
  display: grid; /* use grid layout */
  grid-template-columns: repeat(2, 1fr); /* 4 columns with equal width */
  grid-gap: 10px; /* gaps between the grid items */
  padding: 10px; /* container padding */
  grid-template-rows: auto auto auto;
  
}

.grid-container-precautions {
  display: grid; /* use grid layout */
  grid-template-columns: repeat(3, 1fr); /* 4 columns with equal width */
  grid-gap: 5px; /* gaps between the grid items */
  padding: 10px; /* container padding */
  grid-template-rows: auto auto auto;
  
}

.span-two {
  grid-column: span 2; /* This makes the item span 2 columns */
}

.custom-empty {
  height:20px;


}



.no-scroll {
  overflow: hidden;
}
.input-container {

padding:auto;
margin:auto;


}
.input-container23test {
  position:absolute;
  left:0;
  right:0;
    background-color: #f2f2f2;
    padding: 16px;
    transition: transform 0.3s ease;
    transform: translate3d(0, 0, 0);
  
  }

.keyboard-open {
  transform: translate3d(0, -100%, 0);
}
@media only screen and (max-height: 500px) {
  .input-container {
    position: relative;
    bottom: unset;
  }

  .keyboard-open {
    bottom: unset;
  }
}


.safeSpace{
  margin-top: env(safe-area-inset-top);
  transition: none !important;

  
}


.card-drain {
  background-color: #ffffff;
  border: 0.5px solid black;
  padding: 10px;
  min-height:120px;
  text-align: center;
  height:auto;
}

.card {
  background-color: #ffffff;
  border: 0.5px solid black;
  padding: 10px;
  min-height:120px;
  text-align: center;
  height:auto;

}

.card-time {
  font-size: 24px;
  font-weight: bold;
}

.card-name-container {
  /* existing styles */
  background-color: #f2f2f2; /* set a background color */
  border: 1px solid #ccc; /* add a border */
  padding: 5px; /* add padding */
  margin-top: 10px; /* adjust as needed */
  height:auto;
  width:100%;
  align-items: center;
}
/*the real card name container */
.card-name-containers {
  /* existing styles */
  background-color: #f2f2f2; /* set a background color */
  border: 1px solid #ccc; /* add a border */
  padding: 10px; /* add padding */
  margin-top: 10px; /* adjust as needed */
  height:auto;

  align-items: center;
}
.card-name {
  /* existing styles */
  font-size: 20px; /* increase font size */
  color: #333; /* change color */
  font-weight: bold; /* make text bold */
  text-transform: uppercase; 
}

.card-options-container{
    display: flex; /* use flexbox layout */
  flex-wrap: wrap; /* allow elements to wrap to next line */
  align-items: center; /* align elements to the center */
  justify-content: center; /* center align elements horizontally */
}
.card-options-container-header{
  display: flex; /* use flexbox layout */
flex-wrap: wrap; /* allow elements to wrap to next line */
align-items: center; /* align elements to the center */
justify-content: center; /* center align elements horizontally */
}
.card-options-containers{
  display: grid; /* use flexbox layout */
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
flex-wrap: wrap; /* allow elements to wrap to next line */
align-items: center; /* align elements to the center */
justify-content: center; /* center align elements horizontally */
}

.general-info-container{
  display: grid; /* use flexbox layout */
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5px;
flex-wrap: wrap; /* allow elements to wrap to next line */
align-items: center; /* align elements to the center */
justify-content: center; /* center align elements horizontally */

}


.radioButton {

  font-size: 18px;
  height:30px;
  line-height: 30px;
}

.radio-container{
  display: grid; /* use flexbox layout */
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1px;

}


.form-label {
  position: sticky;
  top: 0; /* Stick to the top */
  background-color: white; /* Make sure it stands out */
  font-weight: bold;
  padding: 5px 10px; /* Add some padding for spacing */
  z-index: 1; /* Ensure it's above the scrollable content */
}











.general-info-item {
  position: relative; /* Make sure the child element is positioned relative */
  padding: 10px; /* Adjust the padding as needed */
  border: 1px solid #000; /* Add a border around each item */
  box-sizing: border-box;
  height:auto;
  
  overflow-x:hidden;
  /* Other styling for your items here */
}


.general-info-container label {
  padding:0;
  margin:0
}


.drawer-button-container{
  display: grid; /* use flexbox layout */
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
flex-wrap: wrap; /* allow elements to wrap to next line */
align-items: center; /* align elements to the center */
justify-content: center; /* center align elements horizontally */
}
.drawer-button-container-drains{
  display: grid; /* use flexbox layout */
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
flex-wrap: wrap; /* allow elements to wrap to next line */
align-items: center; /* align elements to the center */
justify-content: center; /* center align elements horizontally */
}
.drawer-button-container button {
  width:100%;
}
.drawer-button-container-drains .btn {
  width:100%;
  
}


.card-options-container button {
  width:100%;
  height:50px;
}


.card-options-container-drains {
  display: grid; /* use flexbox layout */
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
flex-wrap: wrap; /* allow elements to wrap to next line */
align-items: center; /* align elements to the center */
justify-content: center; /* center align elements horizontally */
}

.card-options-container-drains button {
  width:100%;
  height:50px;

}



.btn {
  flex: 1 0 auto;
  height:30px;
  color: white;
  text-align: center;
  text-decoration: none;
  display: flex;
  font-size: 16px;
  padding:auto;
margin-right:2px;
  margin-left:auto;
  margin-bottom:2px;
  flex-basis:40%;
  cursor:pointer;
   line-height: 40px;
    align-items: center;
  justify-content:space-evenly
  

}
.btn:hover {
  color:white;

}



.top-container {
  display:flex;
align-items: center;
justify-content: center;
 background:black;
  /* existing styles */
  position: fixed; /* position the container fixed */
  bottom: 0; /* position the container at the bottom of the screen */
  left: 0; /* position the container at the left of the screen */
  right: 0; /* position the container to the right of the screen */
  width: 100%; /* make the container take the full width of the screen */
  z-index: 10; /* set a higher z-index value to make sure the container is on top */

}

.tops {
  position:fixed;
  top:0;
  z-index:100;
  width:100%;
  background:white
}

.topText {
  /* existing styles */
  margin: 0 auto; /* center the input horizontally */
  width:80%;
}

#topTextButton {
  height:40px;
  width:300px;
}

input[type='text'] {
width:30%;
  height:45px;
padding: 12px 20px;
margin: 8px 0;
box-sizing: border-box;
border: 2px solid #ccc;
border-radius: 4px;
}

.form-select {
  margin-left:5px;
  margin-right:5px;
  
  width:30%;
  -webkit-appearance: none; /* remove default select styling on iOS */
  background-color: #f2f2f2; /* set a background color */
  border: 1px solid #ccc; /* add a border */
  padding: 10px; /* add padding */
  font-size: 16px; /* increase font size */
  color: #3313; /* change color */
  font-weight: bold; /* make text bold */
}

.form-select::before {
  content: ''; /* empty content */
  position: absolute; /* set the position absolute */
  right: 10px; /* set the position on the right */
  top: 50%; /* set the position in the middle */
  transform: translateY(-50%); /* center the element */
  width: 0; /* set the width 0 */
  height: 0; /* set the height 0 */
  border-left: 10px solid transparent; /* left arrow */
  border-right: 10px solid transparent; /* right arrow */
  border-top: 10px solid #333; /* top arrow */
}

.form-select::-webkit-select-button {
  /* styles for the select button on iOS */
  background-color: #f2f2f2; /* set a background color */
  border: 1px solid #ccc; /* add a border */
  padding: 10px; /* add padding */
  font-size: 16px; /* increase font size */
  color: #333; /* change color */
  font-weight: bold; /* make text bold */
}


#navbar {
  /* existing styles */
  display: flex; /* use flexbox layout */
  justify-content: space-around; /* distribute buttons evenly across the navbar */
  height:50px;
}

#navbar button {
  /* existing styles */
  margin-right:2px;
  margin-top:2px;
  height:40px;
  flex: 1; /* make buttons flexible */
}

.fill-it-up {
  height:100vh;
}

#bottomBar {
  width:100%;
  margin:auto;
  z-index:10;
  position: fixed; /* position the navbar fixed */
  bottom:0;/* position the navbar at the bottom of the screen */
  left: 0; /* position the navbar at the left of the screen */
  right: 0; /* position the navbar to the right of the screen */
  display: flex; /* use flexbox layout */
  justify-content: space-between; /* distribute elements evenly across the navbar */
  align-items: center; /* center elements vertically */
  height: 150px; /* set the height of the navbar */
  background-color: #f2f2f2; /* set a background color */
  padding-left:10px;
  padding-right:10px;
  padding-bottom:120px;
  padding-top:10px;
  flex-wrap:wrap;
  
}



.nav-item {
  /* existing styles */
  display: flex; /* use flexbox layout */
  flex-direction: column; /* stack elements vertically */
  align-items: center; /* center elements horizontally */
  text-align: center; /* center text */
  color: #333; /* set text color */
  font-size: 14px; /* set font size */
}
.bottomRow {
  /* existing styles */
  width: 100%; /* take up full width of navbar */
  display: flex; /* use flexbox layout */
  justify-content: space-around; /* distribute elements evenly across the row */
}

.nav-item i {
  /* existing styles */
  
  font-size: 24px; /* set icon size */
  color: #333; /* set icon color */
}


.bottomRow button {
    flex: 1 0 auto;

}

.nav-item.active {

  /*border-top:3px solid blue;*/

    font-size: 14px; /* set font size */
}

.btn-dark-dark{
  border:1px solid rgb(255, 98, 0);
  background: rgb(255, 98, 0);
}

.btn-dark-dark:hover {
  color:white
}

div {
  user-select: none;
}

.btn-secondary-secondary {
  border:1px solid rgb(188, 2, 244);
  background: rgb(188, 2, 244);
}

.btn-secondary-secondary:hover {
  color:white
}

html, body {
  overflow-x: hidden;

  background:white;


}
body {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  letter-spacing: 0.004em;
  color: black;
  background:white;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*.navbay {
  position:fixed;
  top: env(safe-area-inset-top);
  right:0;
  left:0;
  bottom:0;
  z-index: 10;
  height:50px;
  background:blue;
  display:flex;
  padding: 0 10px;
}*/

.news-ticker {
  position: fixed;
  top: 56px; /* Adjust this value to match the height of your navbar */
  left: 0;
  right: 0;
  z-index: 998; /* Slightly lower than the navbar */
  background: #fff; /* Change the background color as needed */
  color: #333; /* Change the text color as needed */
  padding: 10px 0; /* Add padding for spacing */
  overflow: hidden; /* Hide overflow content */
  animation: ticker 10s infinite linear;
  flex-direction: row;
}



@keyframes ticker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.news-ticker-item {
  width: 200px;
  height: 100px;
  margin: 10px;
  padding: 10px;
  border: 1px solid black;
}

.scrollable-content {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* Enables momentum scrolling on iOS devices */
}



.navbay {
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: env(safe-area-inset-top);
  left: 0;
  right: 0;
  z-index: 999;
  background: #0065fc;
  color: #27173E;
}
.navbay:before {
  content: '';
  height: env(safe-area-inset-top);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99999;
  background: #0065fc;
  
}
.navbay:before {
  background: #0065fc !important;
}
.bg-primary {
  background:#0065fc
}
.logoy {

  height: 35px;
  align-items: center;
  justify-content: center;
  align-content: center;

padding:auto;
margin:auto;

}

.rightMenu {
position:absolute;
right:20px;
top:10px
}

.pageChanger {
  position:absolute;
  right:70px;
  top:10px
  }



.leftMenu {
  position:absolute;
  left:20px;
  top:10px
  }


  .center img {
    background:#0065fc;
    width:90vw;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
   transform: translate(-50%, -50%);
  }

  .disable-scroll {
    overflow-y: hidden;
  }
  .styled-button {

      background-color: #4CAF50;
      border: none;
      color: white;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
    
  }
  .input-with-button {
    display: flex;
    align-items: center;
  }
  
  .input {
    flex: 1;
    margin-right: 10px;
  }

  .ant-modal-root {
    overscroll-behavior: contain;
    overflow: auto;
  }





  .autocomplete-container {
    position: relative;
  }
  
  .autocomplete-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;

  }
  
  .autocomplete-list {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 0px;
    margin-bottom:3rem;
    padding: 0;
    background-color: #fff;
 
    border-radius: 5px;
    list-style: none;
  }
  
  .autocomplete-item {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .autocomplete-item:hover {
    background-color: #f2f2f2;
  }
  
  .autocomplete-selected {
    margin-top: 5px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  @media screen and (min-width: 768px) {
    .autocomplete-input {
      width: 300px;
    }
  
    .autocomplete-list {
      width: 300px;
    }
  }





  .modal-header {


    
   

    border-bottom: 1px solid #e7e7e7;
    width:60vw;
    margin-top:20px;
  }

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.modal-sections {
  width:30%;
}

.modal-section {
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  width:80vw;
  margin:auto;
  transition: background-color 0.2s ease-in-out;
}

.modal-section:hover {
  background-color: #f7f7f7;
}



table {
  border-collapse: collapse;
  margin: 0 auto;
  margin-top:2rem;
}

td {
  padding: 0.5rem;
}

.section-1 {
  border-right: 1px solid #ccc;
  text-align: center;
  width:33%;
}

.section-2 {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  text-align: center;
  width:33%;
}

.section-3 {
  border-left: 1px solid #ccc;
  text-align: center;
  width:33%;
}

 /* Style for the custom button class */
.custom-button {
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 20px;
  margin: 5px;
  font-size: 16px;
}

/* Style for the custom span class */
.custom-span {
  font-size: 24px;
  font-weight: bold;
  color: #007BFF;
  margin: 0 10px;
}

/* Style for the container div class */
.custom-container {
  text-align: center;
}

/* Hover effect for custom buttons */
.custom-button:hover {
  background-color: #0056b3;
}

